import { Component, HostListener, OnInit } from "@angular/core";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public isLoading: boolean = true;
  public userActivity;

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        setTimeout(() => {
          this.isLoading = true;
        }, 0);

        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationCancel) {
        // Show loading indicator
        setTimeout(() => {
          this.isLoading = false;
        }, 0);

        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        setTimeout(() => {
          this.isLoading = false;
        }, 0);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        setTimeout(() => {
          this.isLoading = false;
        }, 0);
      }
    });
  }

  @HostListener('window:mousemove') refreshUserStateMouseMove() {
    clearInterval(this.userActivity);
    this.setInterval();
    this.updateExpireTime();
  }

  @HostListener('window:scroll') refreshUserStateScroll() {
    clearInterval(this.userActivity);
    this.setInterval();
    this.updateExpireTime();
  }

  @HostListener('window:keydown') refreshUserStateKeydown() {
    clearInterval(this.userActivity);
    this.setInterval();
    this.updateExpireTime();
  }

  setInterval() {
    this.userActivity = setInterval(
      () => {
        let expiryTime = Number(localStorage.getItem("expires_in"))
        if (expiryTime < Date.now()) {
          console.log("in logout method");
          localStorage.clear();
          this.router.navigate(["/"]);
        }
      },
      30000
    );
  }

  updateExpireTime() {
    //extent the expiry time for 1hour
    let expires_in = Date.now() + 1200000;
    localStorage.setItem("expires_in", expires_in.toString());
  }
}
