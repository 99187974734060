export class ProgressModel {
  public advancedHealthcareCount: number = 0;
  public nominationOfGuardiansCount: number = 0;
  public personalInformationCount: number = 0;
  public powerOfAttorneyCount: number = 0;
  public assetCount: number = 0;
  public peopleCount: number = 0;
  public progressNumber: number = 0;
  public stepNumber: number = 0;
  public hasChildren: boolean = false;
  public status: string = "inProgress";
  public currentProgress: CurrentProgressModel = new CurrentProgressModel();
  public planName: string = null;
  public planTypeName: string = null;
  public isDocumentsGenerated: boolean = false;
  public planTypeID: number;
  public isChildrenBelowEighteen: boolean = false;
}

export class ProgressListModel {
  public id: number;
  public name: string;
  public path: string;
  public status: string;
  public invalidCount: number;
}

export class CurrentProgressModel {
  public advancedHealthcare: string = "start";
  public nominationOfGuardians: string = "start";
  public personalInformation: string = "start";
  public powerOfAttorney: string = "start";
  public asset: string = "start";
  public assetDistribution: string = "start";
  public people: string = "start";
}
