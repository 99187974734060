import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";

// local imports
import { LongQuestionnaireService } from "@myapp-long-questionnaire/services/long-questionnaire.service";
import { ProgressModel } from "@myapp-shared/models/progress.model";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.css"],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  public isMobileResolution: boolean;
  public baseURLPath: string;
  public progress: ProgressModel = new ProgressModel();

  // subscription
  private subscription = new Subscription();

  constructor(
    private longQuestionnaireService: LongQuestionnaireService,
    private router: Router
  ) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }

    this.baseURLPath = router.url.split("/")[1];
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.baseURLPath = event.url.split("/")[1];
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  isMobile(event) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.longQuestionnaireService.ProgressInfo$.subscribe((data) => {
        this.progress = data;
      })
    );

    // this.subscription.add(
    //   this.longQuestionnaireService
    //     .getLongQuestionnaireProgress(planId, email)
    //     .subscribe((data) => {
    //       this.longQuestionnaireService.ProgressInfo$.next(data);
    //     })
    // );
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
}
