import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// local imports
import { LongQuestionnaireService } from "@myapp-long-questionnaire/services/long-questionnaire.service";
import { PlanDetail } from "@myapp-shared/models/plan.model";
import { Subscription } from "rxjs";

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  
  // {
  //   path: "/home",
  //   title: "Home",
  //   type: "link",
  //   icontype: "ni-shop",
  // },
  // {
  //   path: "/Contact Us",
  //   title: "Contact Us",
  //   type: "link",
  //   icontype: "ni-single-copy-04"
  // },
  // {
  //   path: "/Customer Support",
  //   title: "Customer Support",
  //   type: "link",
  //   icontype: "ni-archive-2",
  //   collapse: "examples",
  //   isCollapsed: true,
  //   children: [
  //    { path: "", title: "Messages", type: "link" },
  //    { path: "", title: "Returns", type: "link" },
  //    { path: "", title: "Refunds", type: "link" },
  //    { path: "", title: "Replacements", type: "link" },
  //   ]
  // },
  {
    path: "/questionnaire/long-questionnaire/basic-information",
    title: "Basic",
    type: "link",
    icontype: "ni ni-single-02"
  },
  {
    path: "/questionnaire/long-questionnaire/nomination-of-guardians",
    title: "Guardians",
    type: "link",
    icontype: "ni ni-circle-08"
  },
  {
    path: "/questionnaire/long-questionnaire/power-of-attorney",
    title: "Attorney",
    type: "link",
    icontype: "ni ni-paper-diploma"
  },
  {
    path: "/questionnaire/long-questionnaire/advanced-healthcare",
    title: "Healthcare",
    type: "link",
    icontype: "fa fa-medkit"
  },
  {
    path: "/questionnaire/long-questionnaire/asset",
    title: "Assets",
    type: "link",
    icontype: "fa fa-building"
  },
  {
    path: "/questionnaire/long-questionnaire/beneficiaries",
    title: "Beneficiaries",
    type: "link",
    icontype: "fa fa-user-plus"
  },
  {
    path: "/questionnaire/long-questionnaire/people-and-roles",
    title: "People",
    type: "link",
    icontype: "fa fa-users"
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: any[];
  public isCollapsed = true;
  public hasChildren: boolean;
  @Input() public plan: any;

  // subscription
  private subscription = new Subscription();

  constructor(private router: Router, private longQuestionnaireService: LongQuestionnaireService) {}

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });

    this.subscription.add(
      this.longQuestionnaireService.hasChildren$.subscribe(value => {
        this.hasChildren = value;
      })
    )
  }
  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      // document.body.classList.add("g-sidenav-show");
      document.querySelectorAll(".side-menu-title").forEach(element => {

        // element.classList.remove("d-none");
      })
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      // document.body.classList.remove("g-sidenav-show");
      document.querySelectorAll(".side-menu-title").forEach(element => {

        // element.classList.add("d-none");
      })
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
  
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
}
