import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";

// local imports
import {
  ProgressModel,
  ProgressListModel,
} from "@myapp-shared/models/progress.model";
import { LongQuestionnaireService } from "@myapp-long-questionnaire/services/long-questionnaire.service";
import { PlanDetail } from "@myapp-shared/models/plan.model";
import { SharedService } from "@myapp-shared/services/shared.service";

@Component({
  selector: "app-long-questionnaire",
  templateUrl: "./long-questionnaire.component.html",
  styleUrls: ["./long-questionnaire.component.css"],
})
export class LongQuestionnaireComponent implements OnInit, OnDestroy {
  public isMobileResolution: boolean;

  // subscription
  private subscription = new Subscription();

  public progress: ProgressModel = new ProgressModel();
  public plan: any;

  public progressList: ProgressListModel[] = [
    {
      id: 1,
      name: "Basic",
      path: "/questionnaire/long-questionnaire/basic-information",
      status: "inProgress",
      invalidCount: 0,
    },
    {
      id: 3,
      name: "Attorney",
      path: "/questionnaire/long-questionnaire/power-of-attorney",
      status: "inProgress",
      invalidCount: 0,
    },
    {
      id: 4,
      name: "Healthcare",
      path: "/questionnaire/long-questionnaire/advanced-healthcare",
      status: "inProgress",
      invalidCount: 0,
    },
    {
      id: 7,
      name: "People",
      path: "/questionnaire/long-questionnaire/people-and-roles",
      status: "inProgress",
      invalidCount: 0,
    },
  ];
  public progressData: ProgressListModel[];
  public currentURLFull: string;
  public currentURL: string;

  constructor(
    private router: Router,
    private longQuestionnaireService: LongQuestionnaireService,
    private sharedService: SharedService,
    private ref: ChangeDetectorRef
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentURLFull = val.url;
        this.currentURL = val.url.split("/")[1];
      }
    });
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  @HostListener("window:resize", ["$event"])
  isMobile(event) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  ngOnInit(): void {
    let planId = parseInt(localStorage.getItem("planId"));
    let email = localStorage.getItem("emailId");

    this.subscription.add(
      this.longQuestionnaireService.ProgressInfo$.subscribe((data) => {
        this.progress = data;
        this.progressList = this.progressList
          .map((progress) => {
            if (progress.id === 1) {
              progress.status =
                this.progress.currentProgress.personalInformation;
              progress.invalidCount = this.progress.personalInformationCount;
            } else if (progress.id === 3) {
              progress.status = this.progress.currentProgress.powerOfAttorney;
              progress.invalidCount = this.progress.powerOfAttorneyCount;
            } else if (progress.id === 4) {
              progress.status =
                this.progress.currentProgress.advancedHealthcare;
              progress.invalidCount = this.progress.advancedHealthcareCount;
            } else if (progress.id === 7) {
              progress.status = this.progress.currentProgress.people;
            }

            return progress;
          })

        this.progressData = Array.from(this.progressList);

        if (this.progress.hasChildren || this.progress.planTypeID !== 1 || this.progress.isChildrenBelowEighteen) {
          if (this.progress.hasChildren || this.progress.isChildrenBelowEighteen) {
            this.progressData.push({
              id: 2,
              name: "Guardians",
              path: "/questionnaire/long-questionnaire/nomination-of-guardians",
              status: this.progress.currentProgress.nominationOfGuardians,
              invalidCount: this.progress.nominationOfGuardiansCount,
            })
          }
          if (this.progress.planTypeID !== 1) {
            this.progressData.push({
              id: 5,
              name: "Assets",
              path: "/questionnaire/long-questionnaire/asset",
              status: this.progress.currentProgress.asset,
              invalidCount: this.progress.assetCount,
            },
              {
                id: 6,
                name: "Beneficiaries",
                path: "/questionnaire/long-questionnaire/beneficiaries",
                status: this.progress.currentProgress.assetDistribution,
                invalidCount: 0,
              },)
          }
        } else {
          this.progressData = this.progressList;
        }
        this.progressData = this.progressData.sort((a, b) => a.id - b.id);


        this.longQuestionnaireService.hasChildren$.next(
          this.progress.hasChildren
        );

        this.longQuestionnaireService.isChildrenBelowEighteen$.next(
          this.progress.isChildrenBelowEighteen
        );
        //this.sharedService.progressList$.next(this.progressList);
      })
    );

    if (planId) {
      this.subscription.add(
        this.longQuestionnaireService
          .getLongQuestionnaireProgress(planId, email)
          .subscribe((data) => {
            this.longQuestionnaireService.ProgressInfo$.next(data);
            this.sharedService.progressList$.next(this.progressList);
          })
      );

      this.subscription.add(
        this.longQuestionnaireService.getPlan(planId).subscribe((data) => {
          if (data === null || data === undefined) {
            localStorage.clear();
            this.router.navigate(["/"]);
            return;
          }

          this.plan = data;
          this.sharedService.plan$.next(this.plan);
          console.log(this.plan);

          //if (this.plan.planTypeId === 1) {
          //  this.progressList = this.progressList.filter(list => list.id !== 5);
          //  this.progressList = this.progressList.filter(list => list.id !== 6);
          //}
          //this.sharedService.progressList$.next(this.progressList);
        })
      );
    }

    // this.sharedService.progressList$.subscribe(data => {this.progressData = data;
    //   //this.ref.detectChanges();
    // });
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
}
