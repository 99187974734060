import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

//Local Imports
import {
  AdvancedHealthcareModel,
  AssetDistribution,
  NominationOfGuardiansModel,
  PersonalInformationModel,
  PowerOfAttorneyModel,
  AssetDistributionDetails,
  Asset,
  Contact,
  AllPeopleAndRolesDetails,
} from "@myapp-long-questionnaire/models/long-questionnaire-model";
import { ProgressModel } from "@myapp-shared/models/progress.model";
import { PaymentIntents } from "@myapp-long-questionnaire/Models/PaymentIntents";
import { PlanDetail } from "@myapp-shared/models/plan.model";
import { Url } from "url";

@Injectable({
  providedIn: "root",
})
export class LongQuestionnaireService {
  public ProgressInfo$ = new BehaviorSubject(new ProgressModel());
  public hasChildren$ = new BehaviorSubject(false);
  public isChildrenBelowEighteen$ = new BehaviorSubject(false);
  public AddressStateErrorMessage$ = new BehaviorSubject(null) ;

  constructor(private http: HttpClient) { }

  getPlan(planId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<any>(
      `/api/Plan/GetPlan?planID=${planId}`,
      httpOptions
    );
  }

  getPersonalInformation(
    planId: number,
    email: string
  ): Observable<PersonalInformationModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<PersonalInformationModel>(
      `/api/LongQuestionnaire/GetPersonalInformation?planID=${planId}&UserEmail=${email}`,
      httpOptions
    );
  }


  Checkout(
    planId: number,
    email: string
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<any>(
      `/api/Plan/Checkout?planId=${planId}&email=${email}`,
      httpOptions
    );
  }


  savePersonalInformation(
    personalInformation: PersonalInformationModel, isDataChanged: boolean
  ): Observable<PersonalInformationModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<PersonalInformationModel>(
      `/api/LongQuestionnaire/SavePersonalInformationDetails?isDataChanged=${isDataChanged}`,
      personalInformation,
      httpOptions
    );
  }

  saveChild(child: Contact, planID: number, isDataChanged: boolean): Observable<Contact> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<Contact>(
      `/api/LongQuestionnaire/SaveChild?planID=${planID}&isDataChanged=${isDataChanged}`,
      child,
      httpOptions
    );
  }

  deleteChild(child: Contact, planID: number, isDataChanged: boolean): Observable<Contact> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<Contact>(
      `/api/LongQuestionnaire/DeleteChild?planID=${planID}&isDataChanged=${isDataChanged}`,
      child,
      httpOptions
    );
  }

  checkPartnerEmail(
    planID: number,
    email: string
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<any>(
      `/api/LongQuestionnaire/CheckPartnerEmail?planID=${planID}&UserEmail=${email}`,
      httpOptions
    );
  }

  getNominationGuardians(
    planId: number,
    email: string
  ): Observable<NominationOfGuardiansModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<NominationOfGuardiansModel>(
      `/api/LongQuestionnaire/GetNominationOfGuardians?planID=${planId}&userEmail=${email}`,
      httpOptions
    );
  }

  saveNominationGuardians(
    nominationOfGuardians: NominationOfGuardiansModel, isDataChanged: boolean
  ): Observable<NominationOfGuardiansModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<NominationOfGuardiansModel>(
      `/api/LongQuestionnaire/SaveNominationOfGuardians?isDataChanged=${isDataChanged}`,
      nominationOfGuardians,
      httpOptions
    );
  }

  getPowerOfAttorney(
    planId: number,
    email: string
  ): Observable<PowerOfAttorneyModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<PowerOfAttorneyModel>(
      `/api/LongQuestionnaire/GetPowerOfAttorney?planId=${planId}&email=${email}`,
      httpOptions
    );
  }

  savePowerOfAttorney(
    powerOfAttorney: PowerOfAttorneyModel, isDataChanged: boolean
  ): Observable<PowerOfAttorneyModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<PowerOfAttorneyModel>(
      `/api/LongQuestionnaire/SavePowerOfAttorney?isDataChanged=${isDataChanged}`,
      powerOfAttorney,
      httpOptions
    );
  }

  getAdvancedHealthcare(planId: number, email: string): Observable<AdvancedHealthcareModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<AdvancedHealthcareModel>(
      `/api/LongQuestionnaire/GetAdvancedHealthcare?planId=${planId}&UserEmail=${email}`,
      httpOptions
    );
  }

  saveAdvancedHealthcare(
    advancedHealthcare: AdvancedHealthcareModel, isDataChanged: boolean
  ): Observable<AdvancedHealthcareModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<AdvancedHealthcareModel>(
      `/api/LongQuestionnaire/SaveAdvancedHealthcare?isDataChanged=${isDataChanged}`,
      advancedHealthcare,
      httpOptions
    );
  }

  getAssets(planId: number, email: string): Observable<Asset> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<Asset>(
      `/api/LongQuestionnaire/GetAssets?planId=${planId}&UserEmail=${email}`,
      httpOptions
    );
  }

  saveAssets(asset: Asset, isDataChanged: boolean): Observable<Asset> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<Asset>(
      `/api/LongQuestionnaire/SaveAssets?isDataChanged=${isDataChanged}`,
      asset,
      httpOptions
    );
  }

  savePropertiesOwnedAssets(asset: Asset): Observable<Asset> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<Asset>(
      "/api/LongQuestionnaire/SavePropertiesOwnedAssets",
      asset,
      httpOptions
    );
  }

  saveOtherOwnedAssets(asset: Asset): Observable<Asset> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<Asset>(
      "/api/LongQuestionnaire/SaveOtherOwnedAssets",
      asset,
      httpOptions
    );
  }

  getLongQuestionnaire(planId: number, email: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<any>(
      `/api/LongQuestionnaire/GetLongQuestioannaire?planId=${planId}&email=${email}`,
      httpOptions
    );
  }


  updatePayment(planId: number, email: string, paidDateTime: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<any>(
      `/api/LongQuestionnaire/UpdatePaymentDetails?planId=${planId}&email=${email}&paidDateTime=${paidDateTime}`,
      httpOptions
    );
  }


  getLongQuestionnaireProgress(planId: number, email: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<any>(
      `/api/LongQuestionnaire/GetLongQuestionnaireProgress?planID=${planId}&userEmail=${email}`,
      httpOptions
    );
  }

  getAssetDistribution(planId: number, email: string): Observable<AssetDistributionDetails> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<AssetDistributionDetails>(
      `/api/LongQuestionnaire/GetAssetDistribution?planId=${planId}&UserEmail=${email}`,
      httpOptions
    );
  }

  deleteAssetDistribution(
    assetDistribution: AssetDistribution, isDataChanged: boolean
  ): Observable<AssetDistribution> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<AssetDistribution>(
      `/api/LongQuestionnaire/DeleteAssetDistribution?isDataChanged=${isDataChanged}`,
      assetDistribution,
      httpOptions
    );
  }

  saveAssetDistribution(
    assetDistributionDetails: AssetDistributionDetails, isDataChanged: boolean
  ): Observable<AssetDistributionDetails> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<AssetDistributionDetails>(
      `/api/LongQuestionnaire/SaveAssetDistribution?isDataChanged=${isDataChanged}`,
      assetDistributionDetails,
      httpOptions
    );
  }

  getAllPeopleAndRoles(planId: number, email: string): Observable<AllPeopleAndRolesDetails> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.get<AllPeopleAndRolesDetails>(
      `/api/LongQuestionnaire/GetAllPeopleAndRoles?planID=${planId}&UserEmail=${email}`,
      httpOptions
    );
  }

  saveAllPeopleAndRoles(allPeopleAndRolesDetails: AllPeopleAndRolesDetails): Observable<AllPeopleAndRolesDetails> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }),
    };
    return this.http.post<AllPeopleAndRolesDetails>(
      "/api/LongQuestionnaire/SaveAllPeopleAndRoles",
      allPeopleAndRolesDetails,
      httpOptions
    );
  }

  getPaymentDetails(secretKey): Observable<PaymentIntents> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + secretKey
      }),

    };

    return this.http.get<PaymentIntents>("https://api.stripe.com/v1/payment_intents", httpOptions);
  }

}
