import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";

// local imports
import {
  ProgressListModel,
  ProgressModel,
} from "@myapp-shared/models/progress.model";
import { SharedService } from "@myapp-shared/services/shared.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-progress-slider",
  templateUrl: "./progress-slider.component.html",
  styleUrls: ["./progress-slider.component.css"],
})
export class ProgressSliderComponent implements OnInit, OnDestroy {
  @Input() public progress: ProgressModel = new ProgressModel();
  @Input() public currentURL: string;
  @Input() public progressData: ProgressListModel[];
  @Input() public plan: any;

  public lastName: string;
  public firstName: string;
  private subscription = new Subscription();
  constructor(private sharedService: SharedService, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.lastName = localStorage.getItem("lastName");
    this.firstName = localStorage.getItem("firstName");
    this.sharedService.progressList$.subscribe(data => {this.progressData = data;
      //this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
}
