import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-not-supported",
  templateUrl: "./not-supported.component.html",
  styleUrls: ["./not-supported.component.css"],
})
export class NotSupportedComponent implements OnInit {
  public status: string = "";
  public state: string = "";
  public firstName: string = "";
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    console.log(this.route.snapshot);
    this.status = this.route.snapshot.queryParams.status;
    this.state = this.route.snapshot.queryParamMap.get("state");
    this.firstName = this.route.snapshot.queryParamMap.get("firstName");
  }

  navigateToHome() {
    this.router.navigate(["/"]);
  }
}
