import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";

// local imports
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { LongQuestionnaireComponent } from "./layouts/long-questionnaire/long-questionnaire.component";
import { HomeComponent } from "@myapp-home/components/home/home.component";
import { AuthGuard } from "@myapp-shared/guards/auth/auth.guard";
import { LoggedInAuthGuard } from "@myapp-shared/guards/loggedin-auth/loggedin-auth.guard";
import { PageNotFoundComponent } from "@myapp-shared/components/page-not-found/page-not-found.component";
import { NotSupportedComponent } from "@myapp-shared/components/not-supported/not-supported.component";
import { GreetingPageComponent } from "./shared/components/greeting-page/greeting-page.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: "./Modules/dashboard/dashboard.module#DashboardModule",
      },
      //{
      //  path: "setting",
      //  loadChildren: "./Modules/setting/setting.module#SettingModule",
      //},
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "questionnaire",
    component: LongQuestionnaireComponent,
    children: [
      {
        path: "long-questionnaire",
        loadChildren:
          "./Modules/long-questionnaire/long-questionnaire.module#LongQuestionnaireModule",
      },
      {
        path: "document",
        loadChildren:
          "./Modules/generate-documents/document.module#DocumentModule",
      },
      {
        path: "setting",
        loadChildren: "./Modules/setting/setting.module#SettingModule",
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: HomeComponent,
    children: [
      {
        path: "home",
        loadChildren: "./Modules/home/home.module#HomeModule",
      },
    ],
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: "account",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/auth-layout/auth-layout.module#AuthLayoutModule",
      },
    ],
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: "page-not-found",
    component: PageNotFoundComponent,
  },
  {
    path: "greeting-page",
    component: GreetingPageComponent,
  },
  {
    path: "not-supported",
    component: NotSupportedComponent,
  },
  { path: "**", redirectTo: "page-not-found" },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      // useHash: true
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
