import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";

//  PrimeNg module import
import { ToastModule } from "primeng/toast";
import { ConfirmationService, MessageService } from "primeng/api";

// local imports
import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { HomeComponent } from "@myapp-home/components/home/home.component";
import { ProgressSliderComponent } from "@myapp-shared/components/progress-slider/progress-slider.component";
import { LongQuestionnaireComponent } from "./layouts/long-questionnaire/long-questionnaire.component";
import { NotSupportedComponent } from "@myapp-shared/components/not-supported/not-supported.component";

// prime ng
import { ConfirmDialogModule } from "primeng/confirmdialog";
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    HomeComponent,
    ProgressSliderComponent,
    LongQuestionnaireComponent,
    NotSupportedComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ComponentsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ComponentsModule,
    ToastModule,
    ConfirmDialogModule,
  ],
  providers: [MessageService, ConfirmationService],
  bootstrap: [AppComponent],
})
export class AppModule {}
