import { Component, OnInit, ElementRef, Input } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";

import { SharedService } from "@myapp-shared/services/shared.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  @Input() public progressStatus: string;
  @Input() public currentURLFull: string;
  @Input() public isDocumentsGenerated: string;

  public email: string = "";
  public username: string = "";
  public focus;
  public listTitles: any[];
  public location: Location;
  public baseURLPath: string;
  sidenavOpen: boolean = true;
  logoutbool: boolean = false;

  constructor(
    location: Location,
    private router: Router,
    private sharedService: SharedService
  ) {
    this.baseURLPath = router.url.split("/")[1];
    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.baseURLPath = event.url.split("/")[1];

        if (window.innerWidth < 1200) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }

  ngOnInit() {
    this.email = localStorage.getItem("emailId");

    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    /*  this.username = `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`;*/

    this.sharedService.userName$.subscribe((val) => {
      this.username = val;
    });
  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  showDialog() {
    this.logoutbool = !this.logoutbool;
  }
  GetConfirm() {
    console.log("in getconfirm method");

    // this.confirmationService.confirm({
    //   message: "You sure, that you want to Log Out?",
    //   accept: () => this.Logout(),
    // });
  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");

      document.querySelectorAll(".side-menu-title").forEach((element) => {
        element.classList.add("d-none");
      });

      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");

      document.querySelectorAll(".side-menu-title").forEach((element) => {
        element.classList.remove("d-none");
      });

      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  Logout() {
    console.log("in logout method");
    localStorage.clear();
    this.router.navigate(["/account/login"]);
  }
}
